import React, { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols, Props } from 'src/components/admin-table/admin-table';
import { isInviteStateActive, ShowInviteStatus, ShowSafePlayStatus } from 'src/components/areas/area-helpers';
import ExpandedMenu from 'src/components/expanded-menu/expanded-menu';
import StatusLabel from 'src/components/status-label/status-label';
import { GetAreaCoordinators_resourcesByEntityId_results as CoordinatorListItem } from 'src/graphql-types/GetAreaCoordinators';

type CoordinatorsTableProps = Omit<Props<CoordinatorListItem & { id: string }>, 'data' | 'columns'> & {
  data: CoordinatorListItem[] | undefined;
  type: 'area' | 'league';
  hideAreaLeagueColumn?: boolean;
  onRemove?: (coorinator: CoordinatorListItem & { id: string }) => void;
};

const CoordinatorsTable: FC<CoordinatorsTableProps> = ({
  data,
  controls,
  loading,
  onRemove,
  hideAreaLeagueColumn = false,
  type,
  ...remainingProps
}) => {
  const { t } = useTranslation();

  const coordinatorsList = useMemo(
    () =>
      data?.map((coordinator, index) => ({
        ...coordinator,
        id: coordinator.userId,
        key: `${coordinator.userId}${index}`,
      })) ?? [],
    [data],
  );
  const showRoleLabels = (coordinator) => {
    return coordinator.roles?.map((role) => {
      if (role.role === 'teamcoordinator') {
        return (
          <StatusLabel key={role} variety="success">
            {t('area coordinator')}
          </StatusLabel>
        );
      }
      return null;
    });
  };
  const columns: DataCols<CoordinatorListItem & { id: string }> = [
    {
      key: 'person.standardGivenName',
      getValue: (item) => `${item?.person?.standardGivenName ?? ''} ${item?.person?.standardFamilyName ?? ''}`,
      title: t('coordinator name'),
    },
    {
      key: 'person.email',
      getValue: (item) => item?.person?.email ?? item?.userId,
      title: t('email address'),
    },
    {
      key: 'role',
      getValue: (item) => showRoleLabels(item),
      title: t('role'),
      sort: false,
    },
    {
      key: 'status',
      getValue: (item) => <ShowInviteStatus states={item.roles} />,
      title: t('invite status'),
      sort: false,
    },
    ...(hideAreaLeagueColumn
      ? []
      : [
          {
            key: 'entityName',
            getValue: (item) => item?.organisation?.organisationName,
            title: t(type),
            sort: false,
          },
        ]),
    {
      key: 'safePlay',
      getValue: (item) => <ShowSafePlayStatus coordinator={item} />,
      title: t('safeplay approval'),
      sort: false,
    },
    {
      key: 'Actions',
      getValue: (item) =>
        isInviteStateActive(item.roles) ? (
          <ExpandedMenu
            items={[
              {
                key: 'remove',
                label: t('remove'),
                onClick: () => onRemove?.(item),
                warning: true,
              },
            ]}
            buttonText={''}
            buttonIcon
            iconName="md-more-options"
            anchorElement="icon"
          />
        ) : null,
      title: t('actions'),
      sort: false,
    },
  ];

  return (
    <AdminTable data={coordinatorsList} columns={columns} controls={controls} loading={loading} {...remainingProps} />
  );
};

export default CoordinatorsTable;
