import React from 'react';

import { Input, InputProps } from '@mui/material';
import cx from 'classnames';
import Icon from 'src/components/icon/icon';
import { Spacing, useSpacing } from 'src/hooks/spacing';

import * as styles from './input.module.less';

interface Props extends InputProps {
  outlined?: boolean;
  field?: {
    name: string;
    value: string;
  };
  spacing?: Spacing;
  fluid?: boolean;
  onClearClick?: () => void;
  inputProps?: any;
}

const TextInput: React.FC<Props> = ({
  outlined,
  field,
  spacing,
  fluid,
  onClearClick,
  inputProps,
  error,
  ...props
}: Props) => {
  const spacingClass = useSpacing(spacing);
  return (
    <div
      className={cx(styles.container, {
        [styles.inputFluid]: fluid,
      })}
    >
      <Input
        className={cx(styles.input, spacingClass, {
          [styles.inputOutlined]: outlined,
          [styles.inputFluid]: fluid,
          [styles.error]: error,
        })}
        classes={{ input: styles.inputText }}
        inputProps={inputProps}
        {...field}
        {...props}
      />
      {onClearClick && (
        <Icon
          name="sm-close-circle-inverted"
          data-testid="clear-icon"
          className={styles.closeIcon}
          onClick={onClearClick}
        />
      )}
    </div>
  );
};

export default TextInput;
