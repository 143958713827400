import React from 'react';

import Layout from 'src/components/layout/layout';
import LeaguesCoordinators from 'src/components/leagues/leagues-coordinators/leagues-coordinators';
import SEO from 'src/components/seo';

const LeaguesCoordinatorsPage = () => {
  return (
    <Layout>
      <SEO title={'Leagues coordinators'} />
      <LeaguesCoordinators />
    </Layout>
  );
};
export default LeaguesCoordinatorsPage;
