// extracted by mini-css-extract-plugin
export var bodyBase = "leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var bodyLarge = "leagues-module--body-large--1effa leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var bodyLargeBold = "leagues-module--body-large-bold--ce405 leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var bodyRegular = "leagues-module--body-regular--f4e43 leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var bodyRegularBold = "leagues-module--body-regular-bold--dbcf7 leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var bodySmall = "leagues-module--body-small--a323d leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var bodySmallBold = "leagues-module--body-small-bold--0529b leagues-module--body-base--5f854 leagues-module--site-font--11333";
export var borderTop = "leagues-module--border-top--c8384";
export var breakWordContainer = "leagues-module--break-word-container--1d09e";
export var buttonIconBase = "leagues-module--button-icon-base--ec0fa";
export var clickLink = "leagues-module--click-link--991c8";
export var dropdownBase = "leagues-module--dropdown-base--bf583";
export var dropdownSelectBase = "leagues-module--dropdown-select-base--8dfaa leagues-module--text-input--39c6d";
export var flexCol = "leagues-module--flex-col--a0148";
export var formErrorMessage = "leagues-module--form-error-message--8197c";
export var h3 = "leagues-module--h3--67497";
export var h4 = "leagues-module--h4--71c5d";
export var hoverLink = "leagues-module--hover-link--4d74b";
export var hoverRow = "leagues-module--hover-row--232dd";
export var inputField = "leagues-module--input-field--ddcec";
export var membershipContainer = "leagues-module--membership-container--d1731 leagues-module--flex-col--a0148 leagues-module--primary-border--58ca2";
export var membershipHeader = "leagues-module--membership-header--43540";
export var membershipHeading = "leagues-module--membership-heading--e51b6";
export var membershipLabel = "leagues-module--membership-label--14325";
export var moreFiltersBorderClass = "leagues-module--more-filters-border-class--7dbea";
export var pageBg = "leagues-module--page-bg--2edae";
export var pointer = "leagues-module--pointer--09598";
export var primaryBorder = "leagues-module--primary-border--58ca2";
export var searchWrapper = "leagues-module--searchWrapper--d2f58";
export var shadowBoxLight = "leagues-module--shadow-box-light--e28b6";
export var siteFont = "leagues-module--site-font--11333";
export var slideDownAndFade = "leagues-module--slideDownAndFade--fba04";
export var slideLeftAndFade = "leagues-module--slideLeftAndFade--90f68";
export var slideRightAndFade = "leagues-module--slideRightAndFade--ff323";
export var slideUpAndFade = "leagues-module--slideUpAndFade--1613e";
export var statusDecoration = "leagues-module--status-decoration--1c7e2";
export var textInput = "leagues-module--text-input--39c6d";
export var textInverted = "leagues-module--text-inverted--6902c";
export var textMediumDark = "leagues-module--text-medium-dark--30f75";
export var tooltipFont = "leagues-module--tooltipFont--85b35";
export var unstyledButton = "leagues-module--unstyled-button--56c11";